.generateReportBtn{
    border-radius: 3px;
    background: #FAC85B;
    border: none;
    height: 33px;
    width: 143px;
    font-family: 'Montserrat';
    font-size: 11px;
    font-weight: 500;
}
.generateReportth{
    text-align: right;
}
.colorPending{
    color : #B5322A
}
.GeneratedReportContainer{
    background: white;
    padding: 50px 20px 50px 20px;
    border-radius: 10px;
    width: 98%;

}
.HorizontalLine{
    height: 100%;
    position: absolute;
    right: 8px;
    border: 1px solid gray;
    top: 0px;
    opacity: 0.7;

}
.SaleReportHEading{
    font-size: 18px;
    opacity: 0.6;
}
.SaleReportHEading1{
    font-size: 24px;
}
.W_90{
    width: 90%;
}
.GenratedReportTable{
.ExcludingText{
    font-size: 11px;
    opacity: 0.6;
    font-weight: 500;
}
.table td, .table th {
 
    border-top: 0px solid #dee2e6;
    text-align: center;

}
.table td {
 

    font-size: 11px;
    font-family: 'Poppins';
    font-weight: 500;
}
.table thead th {
    border-bottom: 2px solid #949494;
    padding-bottom: 22px;

}
.borderTp{
    border-top: 2px solid #949494;
    padding-top: 22px;
    border-bottom: 2px solid #949494;

}
.table th {
    border-top: 0px solid #dee2e6;
    text-align: center;
    padding: 45px;
}
.GenratedReportTotalEaring{
    margin: 0px;
    text-align: left;
    font-size: 24px;
}
}
.GenratedReportTable2{
    .ExcludingText{
        font-size: 11px;
        opacity: 0.6;
        font-weight: 500;
    }
    .table td, .table th {
     
        border-top: 0px solid #dee2e6;
        text-align: center;
    
    }
    .table td {
     
    
        font-size: 11px;
        font-family: 'Poppins';
        font-weight: 500;
        border-top: 2px solid #797979;
        text-align: center;
        padding: 30px;

    }
    .table thead th {
        border-bottom: 2px solid #949494;
        padding-bottom: 22px;
        font-size: 12px;
        font-family: 'Poppins';
        font-weight: 500;
    }
    .borderTp{
        border-top: 2px solid #949494;
        padding-top: 22px;
        border-bottom: 2px solid #949494;
    
    }
    .table th {
        border-top: 0px solid #dee2e6;
        text-align: center;
    }
    .GenratedReportTotalEaring{
        margin: 0px;
    text-align: left;
    font-size: 14px;
    margin-left: 29px;
    }
    .PaymentTransfer{
        
    margin: 0px;
    font-size: 22px;
    font-family: 'Poppins';
    font-weight: 500;

    }
    }

    .PageNAv{
        
    font-size: 13px;
    opacity: 0.6;
    font-family: 'Poppins';
    font-weight: 600;

    }
    .HR{
        width: 100%;
        border-bottom: 1px solid red;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .hide-on-print{
        height: 30px;
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 20px;
        background-color: #FAC85B;
        border: none;
        box-shadow: 0px 0px 2px 0px;
        width: 10%;
        font-family: 'Poppins';
        font-weight: 600;
        }
        .hide-on-print:hover{
            border: 2px solid #FAC85B;
            color: black;
            background: white;
        }
        .hide-on-print:focus{
            outline: none;
            opacity: 0.8;
        }

    @media print{
        .hide-on-print{
            display: none;
        }
    }