.header-img {
  margin-left: 8px;
  margin-right: 8px;
}

.header-img:hover {
  cursor: pointer;
  opacity: 0.8;
}

.headerName {
  text-align: center;
  height: 36px;
  width: 36px;
  background: rgb(181, 50, 42);
  border-radius: 50%;
  font-size: 18px;
  color: white;
  padding-top: 5px;
  margin-top: -4px;

}

.navtogge {
  display: none;
}

.report_img {
  height: 16px !important;
  width: 14px !important;
}

.logoutLabel {
  font-size: 12px;
  color: #D04A4A;
}

.logoutLabel:hover {
  opacity: 0.9;
  cursor: pointer;
}

.ml_20 {
  margin-left: 20px;

}

.navbar {
  border: none;
  height: 75px;
  margin-right: 20px;

  >a {
    color: black;
    padding-right: 12px;

    >i {
      font-size: 22px;
    }
  }

  .nav {
    height: 75px;
  }

  .nav-right {
    right: 0;
    position: absolute;
  }

  .navbar-brand {
    height: 75px;
    padding: 28px 25px;

  }

  i {
    font-size: 18px;
  }

  .separator {
    background-color: #EBEDF3;
    float: left;
    width: 1px;
    height: 44px;
    margin-top: 14px;
  }

  .navbar-form {
    margin-top: 15px;

    .form-group {
      display: flex;
      align-items: center;
    }

    .input-group-addon {
      border-radius: 0;
      border: 0;
      padding: 0;

      background-color: transparent;
      font-size: 16px;
      padding: 0 7px;
      border-radius: 4px 0 0 4px;
      width: auto;
    }

    .form-control {
      padding: 8px 6px;
      height: 40px;
      border-radius: 0;
      border: 0;

      font-size: 16px;
      line-height: 1.5;
      display: inline-block;
      width: 100%;
      vertical-align: middle;
      box-shadow: none;

      &::placeholder {
        opacity: 0.5;
        color: #25265F;
        font-family: "Open Sans";
        font-size: 12px;
        line-height: 17px;
      }
    }
  }

  .navbar-nav {
    >li>a {
      padding: 28px 25px;
      color: #25265F;
      font-family: "Open Sans";
      font-size: 12px;
      font-weight: 600;
      line-height: 17px;
    }

    >li>.person-name {
      padding: 28px 25px;
      color: #565656;
    }

    >li>.person-availability {

      color: #565656;
    }

    >li>button {
      padding: 28px 25px;
      color: #25265F;
      font-family: "Open Sans";
      font-size: 12px;
      font-weight: 600;
      line-height: 17px;
      background-color: transparent;
      border: none;
    }

    >li>i {
      padding: 10px;
      color: #2d2926;
      position: relative;
      right: 40px;
      ;

      >img {
        width: 16px;
      }
    }

    >li>.dropdown-menu {
      border-radius: 8px;
      margin-top: -5px;
      border: none;
      top: calc(100% + 5px);
    }
  }
}

@media (max-width: 993px) {
  .navtogge {
    display: none;
    background-color: transparent;
    width: 50px;
    height: 50px;
    border: none;
    // padding-left: -10;
    position: relative;

  }

  .toggle-image {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0
  }

  .navtogge:focus {
    outline: none;
  }

}

@media (max-width: 992px) {
  .navtogge {
    display: block;
    background-color: transparent;
    width: 50px;
    height: 50px;
    border: none;
    // padding-left: -10;
    position: relative;
  }

  .toggle-image {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0
  }

  .navtogge:focus {
    outline: none;
  }

}

@media (min-width: 992px) {

  .navbar-nav>li>.dropdown-menu,
  .dropdown .dropdown-menu {
    transition: all 150ms linear;
    margin-top: -20px;
    visibility: hidden;
    display: block;
    opacity: 0;
    filter: alpha(opacity=0);
  }


  .navbar-nav>li.open>.dropdown-menu,
  .dropdown.open .dropdown-menu,
  .dropup.open .dropdown-menu,
  .bootstrap-table .open .dropdown-menu {
    opacity: 1;
    filter: alpha(opacity=100);
    visibility: visible;
    margin-top: 0px;
  }

  .navbar-nav.navbar-right>li>.dropdown-menu:before {
    left: auto;
    right: 12px;
    border-bottom: 11px solid white;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    top: -11px;
  }
}

.dropdown-menu:before {
  border-bottom: 11px solid white;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  top: -11px;
}