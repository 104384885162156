.hight-table-div
{
    height: 504px;

}
.tableheight{
    height: 400px;

}
.perviousbtn{
    background-color: #AAB1BF;
    font-size: 11px;
}
.roundbtn{
    border-radius: 50%;
    width: 27px;
    height: 27px;
    margin-left: 6px;
    margin-right: 6px;
    border: none;
    padding-top: 3px;
    font-size: 10px;
    background: #F5F5F9;
}
.roundbtn:hover{
    background-color: black;
    color: white;
}

.roundbtn:focus{
    outline: none;
}
.navbtn{
    width: 100%;
    color: #AAB1BF;
    border: none;
    background: #F5F5F9;
    font-size: 11px;
    font-weight: 700;
    font-size: 11px;
    height: 27px;
}
.navbtn:hover{
    background-color: black;
    color: white;
}
.navbtn:focus{
    outline: none;
}
.downloadtext{
    font-size: 11px !important;
    font-family: 'Montserrat' !important;
    color: #6064DB !important;
    text-decoration: underline;
    font-weight: 500 !important;
}
.downloadtext{
    cursor: pointer;
    opacity: 0.8 ;
}