.SellingHistoryTopBarCard{
    -webkit-box-shadow: 0px 1px 2px 0px rgba(141, 137, 137, 0.75);
-moz-box-shadow: 0px 1px 2px 0px rgba(141, 137, 137, 0.75);
box-shadow: 0px 1px 2px 0px rgba(141, 137, 137, 0.75);
background-color: white;
border-radius: 9px;
padding: 24px;
padding-left: 20px !important;

}
.EaringRs{
    font-size: 28px;
    font-family: 'Montserrat';
    color: #0E7542;
    font-weight: 500;
}
.PendingRs{
    font-size: 28px;
    font-family: 'Montserrat';
    color: #517A95;
    font-weight: 500;

}
.totalEaring{
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 500;
}
.select_days{
    width: 100%;
    border: none;
    font-size: 9px;
    font-family: 'Montserrat';
    padding-left: 20px;

}
.select_days:focus{
    outline: none;
}
.viewtext{
    font-size: 9px;
    font-family: 'Montserrat';
    color: #384282;
    margin-top: 7px;
    margin-bottom: 0px;

}
.viewtext:hover{
    opacity: 0.9;
    cursor: pointer;
}
.banknameText{

font-size: 13px;
}
.vertical-center{
    margin-top: auto;
    margin-bottom: auto;
}

@media (max-width: 567px){

.SellingHistoryTopBarCard {
    box-shadow: 0px 1px 2px 0px rgba(141, 137, 137, 0.75);
    background-color: white;
    border-radius: 9px;
    // padding: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 20px !important;
}
.EaringRs {
    font-size: 18px;
}
.PendingRs {
    font-size: 18px;
}
.totalEaring{
    font-size: 10px;
}

}