html,
body {
  font-family: "Montserrat", sans-serif;

  overflow: hidden;
}
.dropdown_nav{
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 18px;

}
.dropdown_p{
  margin: 0;
  line-height: 30px;
  font-size: 11px !important;
  white-space: nowrap;
  position: relative;
  text-transform: capitalize;
  margin-left: 45px;
  color: #0D1B2A;
  opacity: 0.6;
}
body,
.wrapper {
  min-height: 100vh;
  position: relative;
  background-color: #f7f7f7;
}
.sidebar-logo-container{
  padding: 40px;
}
.imgw100{
  width: 100%;
}

.wrapper {
  height: 100vh;
}

.main-panel,
.sidebar {
  max-height: 100%;
  height: 100%;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: #4a4a4a;

  &:focus,
  &:hover {
    text-decoration: none;
    color: #4a4a4a;
  }
}

.sidebar {
  max-height: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: $sidebar-width;
  display: block;
  z-index: 1;
  color: $sidebar-color;
  font-weight: 200;
  transition-duration: 0.2s, 0.2s, 0.35s;
  background-color: $sidebar-bg;
   .sidebar-wrapper {
    background-color: $sidebar-bg;
    height: calc(100vh - 140px);
    overflow: auto;
  }
  .brand {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    height: 75px;
    width: 100%;
    background-color: $sidebar-bg;
    .logo {
      width: auto;
      height: 75px;

      margin-right: 10px;
    }
    .brand-name {
      color: #f29700;
      letter-spacing: 0px;
      text-align: center;
      font-size: 20px;
      font: Bold 20px/24px Montserrat;
      font-weight: normal;
    }
    .brand-name-bold {
      color: #f29700;
      text-align: center;
      letter-spacing: 0px;
      font-size: 20px;
      font: Bold 20px/24px Montserrat;
    }
    .nav-circle {
      color: #f29700;
      border-radius: 50%;
      border: 4px solid #f29700 !important;
      position: absolute;
      right: 20px;
      width: 25px;
      height: 25px;
    }
  }

  .user-wrapper {
    .user {
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      .photo {
        width: 61px;
        height: 61px;
        border-radius: 50%;
        border: 1px solid #ffffff;
        margin-right: 10px;
      }
      .userinfo {
        flex-grow: 1;
        .username {
          font-size: 14px;
          font-weight: 600;
          line-height: 19px;
        }
        .title {
          font-size: 12px;
          font-weight: 300;
          line-height: 17px;
        }
      }
      .collapse-arrow {
        font-size: 30px;
        transition: all 0.3s ease;
      }
      .collapse-arrow.active {
        transform: rotate(180deg);
      }
    }
    .user-nav > li > a {
      padding-left: 90px;
      color: black;
      &:hover,
      &:active {
        color: $sidebar-active-color;
      }
    }
  }

  > .sidebar-wrapper > .nav {
    font: bold 18px/40px Montserrat;

    .active {
      background-color: #FAC85B;
      border-radius: 5px;
      color: #4B3301;
      opacity: 1;
    }
    > li {
      &.heading {
        margin: 0px;

        margin-left: 12px;
        &:hover {
          background-color: #fff;
        }
      }

      display: inline-block;
      width: 100%;
   
      margin-top: 2px;
      margin-bottom: 2px;
      font-weight: normal;
      padding-top: 6px;
      padding-bottom: 6px;

      &.active > a {
        color: $sidebar-active-color;
      }
      &.list-drop-down:hover {
        background-color: $sidebar-bg;

        .caret {
          border-color: #8d8d8d;
        }
        > a {
          color: $sidebar-color;
        }
      }

      &:hover {
        > a {
          text-decoration: none;
          color: $sidebar-active-color;
        }
        background-color: #FAC85B;
        
      }
      > span {
        color: #0D1B2A;
        opacity: 0.5;

        font-size: 14px;
      }
      &.active > p {
        color: #4B3301;
        opacity: 1;

      }

      > a {
        font-size: 14px;
        color: $sidebar-color;

        &:hover {
          text-decoration: none;
          color: $sidebar-active-color;
        }
      }

      &.active-dropdown .caret {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }
      &.active .caret {
        border-color: #fff;
        position: absolute;
      }

      > div > ul.nav {
        background: $sidebar-sub-menu-bg;
        list-style-type: none;

        > li {
          width: 100%;

          &.active {
            background-color: rgb(185, 130, 42);
          }
          &.active a {
            height: 100%;

            color: $sidebar-active-color;
          }
          > a {
            padding-left: 74px;
            font-size: 14px;
            color: $sidebar-sub-menu-color;
            &:hover {
              color: $sidebar-active-color;
            }
          }
        }
      }
    }

    i {
      font-size: 20px;
      float: left;
      margin-left: 5px;
      margin-right: 5px;
      line-height: 25px;
      width: 30px;
      text-align: center;
      > img {
        width: 19px;
      }
    }

    p {
      margin: 0;
      line-height: 30px;
      font-size: 13px;
      white-space: nowrap;
      position: relative;
      text-transform: capitalize;
      margin-left: 45px;
      color: #0D1B2A;
      opacity: 0.6;

      .caret {
        border: 2px solid #8d8d8d;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin-left: 10px;
        margin-top: auto;
        right: 10px; /* same as height */

        position: absolute;
        top: 12px;
        transition: all 0.2s ease;
      }
    }
  }
  .sidebar > .sidebar-wrapper > .nav p:active{
    opacity: 1;
  }
  .line {
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    border: 1px solid #a1a7bb;
    opacity: 0.1;
  }
}

.main-panel {
  position: relative;
  float: right;

  width: calc(100% - 220px);
  min-height: 100%;
  overflow: auto;
  background-color: #f7f7f7;
}

@media (max-width: 992px) {
  .sidebar,
  .bootstrap-navbar {
    position: fixed;
    display: block;
    top: 0;
    height: 100%;
    width: $sidebar-width;
    right: 0;
    left: auto;
    z-index: 1032;
    visibility: visible;
    overflow-y: visible;
    padding: 0;
    transform: translateX($sidebar-width);
  }
  .main-panel,
  .wrapper-full-page {
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    left: 0;
    width: 100%;
    transform: translateX(0);
  }
  .nav-open {
    .main-panel,
    .wrapper-full-page {
      transform: translateX(-$sidebar-width);
      left: 0;
      overflow: hidden;
    }
    .sidebar {
      transform: translateX(0);
    }
    .close-layer {
      opacity: 1;
      visibility: visible;
    }
  }
}

.close-layer {
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0;
  top: 0;
  left: auto;
  content: "";
  z-index: 1000;
  overflow-x: hidden;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.35);
}
