.earing_card{
height: 102px;
background: #B5322A;
color: white;
padding-top: 15px;
padding-bottom: 15px;
padding-left: 15px;
padding-right: 15px;
width: 100%;
border-radius: 8px;

}
.NoFileImg{
    height: 100%;
    width: 76%;
    margin-top: 69px;
}
.NoNotyText{
    font-size: 26px;
    opacity: 0.4;
    font-family: 'Poppins';
    font-weight: 700;
    margin-top: 28px;
}
text {
   display: none;
}
svg {
    overflow: hidden;
    width: 100% !important;
    height: 177px;
}

.costinner{
    position: absolute;
    left: 38px;
    top: 22px;
}
.earningText{
    font-size: 12px;
}
.earningAmount{
    font-size: 27px;
}
.totalSold_card{
    height: 102px;
    background: #707070;
    color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    border-radius: 8px;
    
    }
    .totalBook_card{
        height: 102px;
        background: #517A95;
        color: white;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        border-radius: 8px;
        
        }
 .editicon{
     position: absolute;
     bottom: 11px;
    left: 35px;
}
.sellinghistory_card{
    height: 327px;
    background: #FFFEFC;
    color:  #4d718d;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    border-radius: 8px;
    
    }
.selling_history_text{
    font-size: 15px;
}
.btn-sellinghistory{
    border-radius: 7px;
    height: 39px;
    width: 203px;
    font-size: 13px;
    font-family: 'Poppins';
    font-weight: 400;
    color: white;
    background: #517A95;
    border: none;
    margin-left: auto;
    margin-right: auto;
}
.bookcategorycard{
    

    height: 327px;
    background: #FEF7E7;
    /* opacity: 0.15; */
    color: #4d718d;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 0px 3px 0px #ede5d4;

}
svg {
    overflow: hidden;
    width: 177px;
    height: 177px;
}
.urdusquare{
    height: 12px;
    width: 12px;
    background: #B5322A;
    position: absolute;
    top: 5px;
    left: 26px;
}
.sindisquare{
    height: 12px;
    width: 12px;
    background: #FAC85B;
    position: absolute;
    top: 5px;
    left: 20px;
}
.englishsquare{
    height: 12px;
    width: 12px;
    background: #778DA9;
    position: absolute;
    top: 5px;
    left: 20px;
}
.langText{
    color: #0D1B2A;
    opacity: 0.6;
    font-size: 12px;
    margin-left: 20px;

}
.addnewbookcard{
    background-color: #eaebed;
    opacity: 0.90;
    height: 211px;
    color: #0D1B2A;
    padding-top: 65px;
    padding-bottom: 65px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 0px 3px 0px #ede5d4;
}
.recentBookCard{
    background-color: #eaebed;
    height: 211px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 0px 3px 0px #ede5d4;
}
.RecentBookCardInner{
    background-color: white;
    border-radius: 15px;
    height: 81px;
    margin-top: 20px;
    padding-top: 5px;

}

.plusicon{
    // margin-top: 56px;

}
.plusicon:hover{
    opacity: 0.9;
    cursor: pointer;
}
.addnewbookText{
    font-size: 18px;
    margin-top: 20px;
    opacity: 0.5;

}
.recentBookpublishText{
    color: #1E555C;
    opacity: 0.70;
    font-size: 16px;
}
.recentBookpublishSeeText{
    color: #1E555C;
    opacity: 0.60;
    font-size: 12px;
}
.recentbookpublishTAbs{
    font-size: 12px;
    color: #0D1B2A;
    opacity: 0.6;

}
.recentbookHr{
    border-bottom: 1px solid #707070;
    opacity: 0.2;
    margin-top: 5px;
    margin-bottom: 5px;
}
.recentBookName{
    font-size: 11px;
    color: #0D1B2A;
    opacity: 0.6;
}
.topSelling_Card {
    height: 223px;
    background: #E7e7e7;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    border-radius: 15px;
}
.topSelling_CardText{
    color: #0D1B2A;
    opacity: 0.7;
}
.topSelling_CardImg{
    width: 100%;
    height: 132px;
}
.notificationCard {
    height: 431px;
    background: #F2f4f7;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    border-radius: 15px;
}
.notificationInnerCard {
    height: 95px;
    background: #ffffff;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    border-radius: 15px;
    box-shadow: 0px 0px 3px 0px #868686;}

.notificationNAmeText{
    font-size: 13px;
    font-family: 'Poppins';
    font-weight: 900;
    color: #0D1B2A;
    opacity: 0.6;
}

.notificationRatedText{
    font-size: 13px;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    color: #0D1B2A;
    opacity: 0.6;
}
.notiprofileimg{
    height: 46px;
    width: 46px;
}
