[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #000000;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #000000;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.continuebtn{
    height: 42px;
    background: #FAC85B;
    border: none;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 600;
    margin-top: 51px;
}
.mt_auto{
    margin-top: auto;
}

//confrim payment

.checkout_heading {
    font-size: 33px;
}
.confirmPaymentInput {
    border: 1px solid #6b99b9;
    height: 44px;
    border-radius: 7px;
    font-size: 17px;
    letter-spacing: 5px;
    font-family: 'Poppins';
    font-weight: 500;
    color: #707070;
}
.confirmPaymentInput:focus{
    outline: none;
    border: 2px solid #BB423B;
}
.paynowbtn {
    height: 44px;
    background: #BB423B;
    color: white;
    border-radius: 7px;
    font-size: 18px;
    border: none;
}
.orderDetail {
    background-color: #F6F6F6;
    border-radius: 3px;
    padding: 25px;
}
.confirmpayent_container{
    background: white;
}
.orderDetailhead {
    color: #606060;
    font-size: 25px;
}
.orderDetailInnerCard {
    background: white;
    margin: 20px;
    padding: 20px;
}
.orderDetailInnerCardText {
    color: #7A7A7A;
    font-size: 13px;
    margin-bottom: 0px;
}
.orderDetailInnerCardBText {
    color: #393939;
    font-size: 20px;
    margin-bottom: 0px;
}
.orderDetailInnerCardBText2 {
    color: #BB423B;
    font-size: 20px;
    margin-bottom: 0px;
}
.confirmpaymentError{
    color: red;
}